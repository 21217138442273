
.MuiPickersModal-dialogRoot{
    width: 353px;
}
.MuiPickersToolbar-toolbar>.MuiGrid-container>.MuiGrid-item{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}
.MuiPickersToolbar-toolbar>.MuiGrid-container>.MuiGrid-item>.MuiGrid-item:last-child{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.MuiPickersToolbar-toolbar>.MuiGrid-container>.MuiGrid-item>.MuiPickerDTToolbar-separator{
    margin: 0 5px 9px 3px;

}

.interview-search-picker-input{
    flex: 1 1 auto;
    border: 0       ;
    padding: .625rem .75rem;
    width: inherit;
    position: relative;
    min-width: 0;
    box-shadow: none;
    font: inherit;
    color: #8898aa;
    height: calc(1.5em + 1.25rem + 2px);
    margin: 0;
    display: block;
    font-size: .875rem;
    background: none;
    box-sizing: border-box;
    transition: all .2s cubic-bezier(.68,-.55,.265,1.55);
    font-weight: 400;
    line-height: 1.5;
    border-radius: .375rem;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    background-clip: padding-box;
    background-color: #FFFFFF;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
}

.search-date-range{
    width: 15rem;

}

.search-date-range {
    border: 1px solid #cad1d7 !important;
    border-radius: 4px !important;

}
.interview-search-picker-input::placeholder{
    color: rgba(136, 152, 170, 0.93);
    font-size: 1rem;

}

.search-date-range .react-datepicker__close-icon::after{
    background: #a7a4a4fa;
}

.search-date-range .react-datepicker{
    display: flex;
}
