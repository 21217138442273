.process-details .MuiAccordionSummary-content {
    margin: 0;
    flex-grow: unset;
}

.process-details .MuiSvgIcon-root {
    width: 25px !important;
    height: 24px !important;
}
.process-details .MuiButtonBase-root {
   padding: 0;
}

.height_one{
    height: 1rem !important;
    min-height: 1.5rem !important;
}

