.date-picker-custom {
  border: 1px solid #cad1d7 !important;
  border-radius: .375rem;
}

.date-picker-custom.MuiInputBase-adornedEnd::before:hover{
  border-bottom: none !important;
}

.date-picker-custom input{
  border: none !important;
}
.MuiDialogActions-root.MuiDialogActions-spacing{
  justify-content: end;
}
.MuiPickersModal-dialogRoot{
  max-width: 310px !important;
}

.vertical-baseline{
  vertical-align: baseline;
}