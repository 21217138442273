body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
    height: 100%;
}

.full-height {
    height: 100%;
}

.swal2-container {
    z-index: 10000 !important;
}

.swal2-popup {
    width: max-content !important;
}

.swal2-icon {
    margin: 1.5em auto 0 !important;
}

.notification-success:before {
    left: unset !important;
    right: 15px !important;
}

.notification-success {
    padding-left: 15px !important;
    width: 200px;
    margin-top: 6rem !important;
}

.notification-container {
    width: 240px !important;
}

#dropdowns-notifications-dropdown-id > .MuiMenu-paper {
    top: 16px !important;

}


.text-ovf1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;
}

.text-ovf2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;
}

.modalEditIntern {
    z-index: 9999 !important;
}

.modalEditIntern .MuiDialog-paperWidthXl {
    max-width: 1400px;
}
.w-80 {
    width: 80% !important;
}
.w-50{
    width: 50%;
}