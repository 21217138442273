.MuiStepLabel-iconContainer>.MuiSvgIcon-root{
    width: 25px;
    height: 22px;
}


.MuiStepLabel-iconContainer>.MuiStepIcon-active{
   color: #56a117 !important;
}
.MuiStepLabel-iconContainer>.MuiStepIcon-completed{
   color: #56a117 !important;
}

.errors-step>.MuiStepLabel-iconContainer>.MuiStepIcon-active{
   color: #ea462f !important;
}